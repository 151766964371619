import './main.css'
import {Elm} from './Main.elm'
import * as serviceWorker from './serviceWorker'
import {
    keeperErrors,
    keeperPromise,
    loginWithKeeper,
    loginWithSigner,
    onKeeperNetworkChanged,
    onKeeperUserChanged,
    reserves
} from '@vires.finance/dapp'

import * as btc from 'bitcoin-address-validation';

import * as gateway from "./Gateway/gateway"
import * as topup from "./Gateway/Topup/topup"
import {send} from "./Gateway/Send/send";


const flavour = process.env.ELM_APP_FLAVOUR ?? 'stage'
const config = require(`./_config/${flavour}.config.json`)

// mintAtoken -> unstack
const commandNames =
    ["deposit", "depositEarlyBirdRefBonus", "withdraw", "borrow", "repay", "collapseDebt"
        , "mintAtoken", "replenishWithAtoken", "redeemAtokens"
        , "claimEbReward", "claimReward", "claimAllRewardsAndAllEbAvailable"
        , "stakeVires", "unstakeVires"
    ]




function fixFeeAsset(params) {
    return {...params, feeAssetId: params.feeAssetId === "WAVES" ? null : params.feeAssetId};
}

document.addEventListener("DOMContentLoaded", async () => {
    // RaF
    const earlyBirdsReferral = "earlyBirdsReferral";

    const urlParams = new URLSearchParams(window.location.search);
    let ref = urlParams.get('ref');
    if (ref) {
        localStorage.setItem(earlyBirdsReferral, ref);
    } else {
        ref = localStorage.getItem(earlyBirdsReferral)
    }
    
    
    
    function preferredLanguage() {
        const translations = ["en","es","ru","zh"];
        if (localStorage.getItem("address")) return translations[0];
        const code2 = navigator.language.substring(0,2).toLowerCase();
        
        return translations.indexOf(code2) >= 0 ? code2 : translations[0];
    }

    const lang = localStorage.getItem("lang") ?? preferredLanguage();
    const trTask = await fetch(`/languages/${lang}.json`);
    const translations = await trTask.json()


    const navBarLocalStorageItem = "nav-bar-vires-live";
    const appConfig =
        {
            ...config,
            translations: translations,
            language: lang,
            assets:
                Object.values(config.assets)
                    .map(a => {
                        return {
                            ...a,
                            leasingAPYBase: a.leasingAPYBase ?? null,
                            staking: a.staking ?? false,
                            // platforms: a.platforms ?? null
                        }
                    }),
            ducksClosed: !!localStorage.getItem(navBarLocalStorageItem),
            ref: ref,
            width: window.innerWidth
        }

    // console.log(appConfig.translations)

    const app = Elm.Main.init({
        node: document.getElementById('root'),
        flags: appConfig
    })

    let commands = null
    let loginResult = null;


    function error(err, action) {
        console.error(err)
        app.ports.error.send(err?.message ?? err?.toString() ?? "Unexpected error")

        gtag('event', 'Error', {action});
    }


    app.ports.login.subscribe(login)


    function login(loginWith) {
        console.log("login with: " + loginWith)
        if (loginWith === "auto") {
            const saved = localStorage.getItem("loginWith");
            loginWith = saved === "keeper" ? saved : "address";
        }

        let loginPromise
        switch (loginWith) {
            case "keeper":
                loginPromise = loginWithKeeper({chainId: config.chainId})
                break
            case "email":
            case "passwordOnly":
            case "ledger":
                loginPromise = loginWithSigner(loginWith)
                break
            case "address":
                const address = localStorage.getItem("address");
                if (address) {
                    app.ports.loggedIn.send(address);
                }
                return;
            case null:
                return
            default:
                throw "Invalid login with: " + loginWith
        }

        loginPromise
            .then(l => afterLogin(l, loginWith))
            .catch(e => loginError(e, loginWith))
    }

    function afterLogin(login, loginWith) {
        loginResult = login
        // console.log(login);
        commands = reserves(config, loginWith === "keeper" ? login.keeper : login.signer);
        localStorage.setItem("loginWith", loginWith);
        localStorage.setItem("address", login.address);
        app.ports.loggedIn.send(login.address)

        gtag('event', 'User',
            {
                action: "login",
                with: loginWith,
            });
    }

    function loginError(e, loginWith) {
        // console.log("Login " + +": " + e);
        let msg
        if (loginWith === "keeper") {
            if (e === keeperErrors.wrongNetwork) {
                msg = "Waves Keeper was unable to login due wrong network"
            } else if (e === keeperErrors.rejectedByUser) {
                msg = "Waves Keeper user login rejected"
            } else if (e === keeperErrors.noKeeper) {
                msg = "Please install Waves Keeper before login"
            }
            msg = "Unable to login, make sure you have at least one account in Waves Keeper."
        }

        app.ports.error.send(msg ?? "Unable to login")

        gtag('event', 'Error',
            {
                action: "login",
                with: loginWith,
            });
    }

    function loggedOut() {
        commands = null;
        loginResult = null;
        const address = localStorage.getItem("address");
        if (address) gateway.clearCredentials(address);

        localStorage.removeItem("loginWith");
        localStorage.removeItem("address");


        app.ports.loggedOut.send(null);
    }

    onKeeperUserChanged(data => {
        if (data.newUser)
            login("keeper")
        else
            loggedOut()
    })

    onKeeperNetworkChanged(loggedOut)

    app.ports.logout.subscribe(loggedOut)


    app.ports.askKeeperInstalled.subscribe(() =>
        keeperPromise.then(x => {
            app.ports.keeperInstalled.send(!!x)
        })
    )

    async function loginAndContinue(action) {
        const address = localStorage.getItem("address");
        if (!address) return;

        // console.log(address)
        // console.log(loginResult)
        // console.log(commands)


        if (loginResult) {
            await action();
            return;
        }

        const loginWith = localStorage.getItem("loginWith");
        let loginPromise;
        switch (loginWith) {
            case "keeper":
                loginPromise = loginWithKeeper({chainId: config.chainId})
                break;
            case "email":
            case "passwordOnly":
            case "ledger":
                loginPromise = loginWithSigner(loginWith)
                break;
        }

        try {
            const login = await loginPromise;
            afterLogin(login, loginWith);
            if (address === login.address) {
                await action();
            } else {
                error("You've signed with different account", "account changed")
            }
        } catch (e) {
            loginError(e, loginWith)
        }
    }


    // dApp Write
    commandNames.forEach(c => {
        app.ports[c].subscribe(async params => {

            async function write() {
                params = fixFeeAsset(params)

                console.log(c)
                console.log(params)

                const claimOrStake = c.startsWith("claim") || c.includes("Vires");

                try {

                    const tx = await commands[c](params);
                    // console.log(tx);
                    const amount = params.displayAmount ?? params.amount ?? params.paymentAmount;
                    params.assetId = claimOrStake ? appConfig.viresAsset.id : params.assetId;

                    app.ports.succeed.send({
                        ...params,
                        id: tx,
                        command: c,
                        amount: amount
                    })

                    const asset =
                        params.assetId === appConfig.viresAsset.id
                            ? appConfig.viresAsset
                            : appConfig.assets.find(a => a.id === params.assetId);

                    const quantity = amount / (10 ** asset.decimals);
                    const value = Math.round((params.gaAmountUsd + Number.EPSILON) * 100) / 100; // todo: add for $vires
                    let marketEventData = {
                        action: c,
                        asset: asset.name,
                        amount: quantity,
                        with: localStorage.getItem("loginWith"),
                        value: value,
                        currency: "USD"
                    };
                    console.log("market")
                    console.log(marketEventData)

                    const eventName = claimOrStake ? "Vires" : "Markets";

                    gtag('event', eventName, marketEventData);

                    if (c.startsWith("deposit")) {
                        let purchaseEventData = {
                            // "transaction_id": tx,
                            // "affiliation": "Google online store",
                            "value": value,
                            "currency": "USD",
                            "items": [
                                {
                                    "id": asset.id,
                                    "name": asset.name,
                                    // "list_name": "Search Results",
                                    // "brand": "Google",
                                    // "category": "Apparel/T-Shirts",
                                    // "variant": "Black",
                                    // "list_position": 1,
                                    "quantity": quantity,
                                    "price": params.gaUsdRate
                                }
                            ]
                        };
                        console.log("purchase****")
                        console.log(purchaseEventData);
                        gtag('event', 'purchase', purchaseEventData);
                    }

                } catch (e) {
                    error(e, c)
                }

            }

            await loginAndContinue(write)
        })
    })


    app.ports.updateCollateral.subscribe(async params => {

        async function updateCollateral() {
            const c = params.collateral ? "enable" : "disable"
            try {
                const tx = await commands[c + "UseAsCollateral"](fixFeeAsset(params))
                app.ports.updateCollateralSucceed.send({...params, id: tx})
            } catch (e) {
                error(e, "updateCollateral")
            }

        }

        await loginAndContinue(updateCollateral)
    })

    async function sendStoredGatewayCredentials(address) {
        const credentials = await gateway.getCredentials(address);
        // console.log(credentials);
        credentials && app.ports.gotGatewayCredentials.send(credentials);
    }

    app.ports.getStoredGatewayCredentials.subscribe(sendStoredGatewayCredentials)

    app.ports.authorizeGateway.subscribe(async (address) => {
        let storedAddress = loginResult?.address || localStorage.getItem("address");
        if (address !== storedAddress) {
            error("Account address was changed. Please re-login", "account changed");
            return;
        }

        async function getCredentials() {
            await gateway.authGateway(config.chainId, loginResult);
            await sendStoredGatewayCredentials(address)
        }

        await loginAndContinue(getCredentials);
    })


    app.ports.getEthereumWalletInstalled.subscribe(async () => {
            const isInstalled = await topup.ethereumWalletInstalled()
            app.ports.gotEthereumWalletInstalled.send(isInstalled);
        }
    )

    app.ports.getEthereumWalletBalance.subscribe(async currency => {
        try {
            const balance = await topup.getBalance(appConfig.assets, currency);
            app.ports.gotEthereumWalletBalance.send({currency, balance});
        } catch (e) {
            console.error(e)
            app.ports.gotEthereumWalletBalance.send({currency, balance: -1});
            // error("Can't get balance")
        }
    })

    app.ports.web3Deposit.subscribe(async params => {
        try {
            const tx = await topup.topup(appConfig.assets, params.platform, params.address, params.token, params.decimals, params.amountFloat);
            console.log("Gateway deposit: " + tx);
            app.ports.web3DepositSucceed.send(params);

            gtag('event', 'Gateway',
                {
                    action: 'top up',
                    platform: params.platform,
                    asset: params.token,
                    amount: params.amountFloat
                });

        } catch (e) {
            console.error(e);
            error(e, "topUp");
        }
    })

    app.ports.manualTopUp.subscribe(params => {
        gtag('event', 'Gateway',
            {
                action: 'top up',
                kind: 'manual',
                platform: params.platform,
                asset: params.token,
            });
    })

    app.ports.send.subscribe(async params => {
        console.log(params);

        async function doSend() {
            try {
                const tx = await send(config, loginResult.signer || loginResult.keeper, fixFeeAsset(params))
                console.log("Gateway withdraw: " + tx)

                app.ports.gatewayWithdrawSucceed.send(params)
                app.ports.walletBalanceChanged.send(null)

                gtag('event', 'Gateway',
                    {
                        action: 'send',
                        platform: params.platform,
                        asset: params.gaAsset,
                        amount: params.gaAmount
                    });

            } catch (e) {
                error(e, "send")
            }
        }

        await loginAndContinue(doSend);
    })

    app.ports.validateBitcoinAddress.subscribe(address => {
        const valid = btc.validate(address);
        app.ports.gotBitcoinAddressValidationResult.send({address, valid});
    })


    // copy to clipboard
    app.ports.copy.subscribe(text => {
        // console.log('copy ' + text);
        navigator.clipboard?.writeText(text);
        app.ports.copied.send(text);
    })

    app.ports.broadcast.subscribe(app.ports.broadcasted.send)

    // restart animation todo: remove ugly hack and reimplement with pure ELM
    // app.ports.runAnimation.subscribe(() => {
    //     const el = document.querySelector(".run-animation")
    //     if (el === null) return
    //     el.classList.remove("run-animation")
    //     void el.offsetWidth
    //     el.classList.add("run-animation")
    // })

    // scroll to element
    app.ports.scroll.subscribe(domId => {
        document.getElementById(domId)?.scrollIntoView()
    })

    // scroll to top
    app.ports.scrollToTop.subscribe(domId => {
        window.scrollTo(0, 0);
    })

    app.ports.getUserAgent.subscribe(() => {
        app.ports.gotUserAgent.send(navigator.userAgent)
    })

    app.ports.closeDucks.subscribe(() => {
        localStorage.setItem(navBarLocalStorageItem, Date.now().toString())
    })

    app.ports.getItem.subscribe(key => {
        app.ports.gotItem.send({key, value: localStorage.getItem(key) ?? null})
    })

    app.ports.setItem.subscribe(params => {
        localStorage.setItem(params.key, params.value)
    })


    function escClicked() {
        document.querySelector("[data-key=escape]")?.click()
    }


    // JS hacks: Bind Enter to submit button
    document.addEventListener("keyup", event => {
        switch (event.key) {
            case "Enter":
                const btn = document.querySelector("button[type=submit]")
                if (btn === null || btn.disabled) return
                btn.click()
                event.preventDefault()
                break

            case "Escape":
                escClicked()
                event.preventDefault()
                break

        }
    })

    document.addEventListener("click", event => {
        if (event.target?.dataset.role === "cancel") {
            escClicked()
        }
    })

    function sendWidth() {
        app.ports.gotWidth.send(window.innerWidth)
    }

    // app.ports.getWidth.subscribe(sendWidth)

    window.onresize = sendWidth;

})


// https://nodes.wavesnodes.com/assets/balance/3PJmNd7oXYHqDD92xYH4f6zxNxEV45togP3

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
