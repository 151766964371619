import {
    broadcastTransfer
} from '@vires.finance/dapp'

export function send(config, signerOrKeeper, params) {

    // params = {
    //     amount: 1 * 10 ** 8, //1 waves
    //     assetId: 'WAVES',
    //     recipient: '3P2VJ6LuR5ENR26oTvivGZpQTxrXUo7bjxr',
    //     fee: 100000,
    // }
    
    // console.log(params)

    return broadcastTransfer(config, signerOrKeeper, params)
}